@tailwind base;
@tailwind components;
@tailwind utilities;

:focus-visible {
  outline: 1px solid #999;
}
html.dark :focus-visible {
  outline: 1px solid rgba(153, 153, 153, 0.5);
}

/* Micro clearfix */
.cf:before,
.cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.cf:after {
  clear: both;
}
